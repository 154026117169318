const wretch = require("wretch").default;
const { decompress } = require("../util/gz");
decompress;

module.exports = ({ state }) => {
  state;
  wretch("/api/get-site-data")
    .get()
    .text()
    .then(decompress)
    .then(JSON.parse)
    .then((data) => {
      Object.keys(data).every((key) => {
        state[key] = data[key];
        return true;
      });

      state.dataLoaded = true;
    });
};
