<template>
  <v-app>
    <v-app-bar dark dense app clipped-left>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <top-navigation></top-navigation>
      </template>

      <template v-if="$vuetify.breakpoint.smAndDown">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-app-bar-title>{{ $route.name }}</v-app-bar-title>
      </template>
    </v-app-bar>

    <v-navigation-drawer
      dark
      v-model="drawer"
      app
      clipped
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <drawer-nav></drawer-nav>
    </v-navigation-drawer>

    <v-main>
      <v-img :src="backgroundSrc" width="100vw" contain position="top">
        <v-container class="text-center">
          <header-contents></header-contents>
          <router-view></router-view>
        </v-container>
      </v-img>
    </v-main>

    <app-footer></app-footer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import AppFooter from "./components/App/AppFooter.vue";
import DrawerNav from "./components/App/DrawerNav.vue";
import HeaderContents from "./components/App/HeaderContents.vue";
import TopNavigation from "./components/App/TopNavigation.vue";

export default {
  name: "App",

  components: {
    TopNavigation,
    DrawerNav,
    HeaderContents,
    AppFooter
  },

  data: () => ({
    drawer: false
  }),

  computed: {
    ...mapGetters(["navItems"]),

    backgroundSrc() {
      return this.$vuetify.breakpoint.smAndDown
        ? "/images/background-sm.png"
        : "/images/background-lg.webp";
    }
  },

  async created() {
    this.$store.dispatch("trackingInfo");
    await this.$store.dispatch("loadSiteData");
  }
};
</script>

<style>
a.icon {
  text-decoration: none;
}
</style>
