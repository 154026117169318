<template>
  <div>
    <v-list dense>
      <template v-for="item in navItems">
        <v-list-item
          :key="item.id"
          :to="item.path"
          v-if="item.type != 'spacer' && !item.children"
        >
          <v-list-item-title>
            {{ item.name.toUpperCase() }}
          </v-list-item-title>
        </v-list-item>
        <v-divider v-if="item.type == 'spacer'" :key="item.id"></v-divider>
        <nav-dropdown
          v-if="item.children"
          :key="item.id"
          :items="item.children"
          :nav-text="item.name"
          position="right"
        ></nav-dropdown>
      </template>
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NavDropdown from "./NavDropdown.vue";
export default {
  components: { NavDropdown },
  computed: {
    ...mapGetters(["navItems"]),
  },
};
</script>
