var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{attrs:{"fixed":"","dark":""}},[_c('v-menu',{attrs:{"top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v(" mdi-message-image-outline ")])]}}],null,true)},[_c('span',[_vm._v("Design Credits")])])]}}])},[_c('v-card',[_c('v-card-title',[_vm._v(" Design Credits ")]),_c('v-card-text',[_vm._v(" A film sculpture by Nancy Cervenka inspired the spiky shape in the header image. ")]),_c('v-card-text',[_c('em',[_vm._v("Snowstorm")]),_vm._v(" by Christina Sng (painting) inspired the pattern in the background image. See all her art work "),_c('a',{attrs:{"href":"https://society6.com/christinasng?fbclid=IwAR0dZHQq2MGgDpCsiB4_ChSl_896E5PqT6LkXSR6ImWtEVCKGFnRGTlyy4k","target":"_blank"}},[_vm._v("here")])]),_c('v-card-text',[_vm._v(" Website Design and Development by Mike Casto ")])],1)],1),_c('v-spacer'),_vm._l((_vm.social),function(item,index){return [_c('div',{key:("social-" + index)},[(item.url)?_c('a',{staticClass:"icon",attrs:{"target":"_blank","href":item.url}},[_c('v-icon',[_vm._v(" "+_vm._s(item.icon)+" ")])],1):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"mx-1",staticStyle:{"height":"16px"},attrs:{"src":item.special.image}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.special.text))])])],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }