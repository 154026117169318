const wretch = require("wretch").default;

module.exports = ({ state }, { item, table, actionType }) => {
  state, item, table, actionType, wretch;
  wretch(`/api/admin/${actionType}-rec`)
    .formData({ table, item: JSON.stringify(item) })
    .post()
    .json()
    .then((response) => {
      response;

      if (actionType == "add") {
        item._id = response._id;
        state.admin[table].push(item);
      } else {
        state.admin[table] = state.admin[table].map((rec) =>
          rec._id == item._id ? item : rec
        );
      }
    });
};
