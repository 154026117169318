<template>
  <v-footer fixed dark>
    <v-menu top offset-y>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip top>
          <template v-slot:activator="{ on: tooltip }">
            <v-icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
              mdi-message-image-outline
            </v-icon>
          </template>
          <span>Design Credits</span>
        </v-tooltip>
      </template>
      <v-card>
        <v-card-title>
          Design Credits
        </v-card-title>
        <v-card-text>
          A film sculpture by Nancy Cervenka inspired the spiky shape in the
          header image.
        </v-card-text>
        <v-card-text>
          <em>Snowstorm</em> by Christina Sng (painting) inspired the pattern in
          the background image. See all her art work
          <a
            href="https://society6.com/christinasng?fbclid=IwAR0dZHQq2MGgDpCsiB4_ChSl_896E5PqT6LkXSR6ImWtEVCKGFnRGTlyy4k"
            target="_blank"
            >here</a
          >
        </v-card-text>

        <v-card-text>
          Website Design and Development by Mike Casto
        </v-card-text>
      </v-card>
    </v-menu>

    <v-spacer></v-spacer>

    <template v-for="(item, index) in social">
      <div :key="`social-${index}`">
        <a class="icon" target="_blank" :href="item.url" v-if="item.url">
          <v-icon>
            {{ item.icon }}
          </v-icon>
        </a>

        <v-tooltip top v-else>
          <template #activator="{on, attrs}">
            <img
              v-bind="attrs"
              v-on="on"
              :src="item.special.image"
              style="height: 16px"
              class="mx-1"
            />
          </template>
          <span>{{ item.special.text }}</span>
        </v-tooltip>
      </div>
    </template>
  </v-footer>
</template>

<script>
export default {
  computed: {
    social() {
      return this.$store.state.social;
    },
    isDevelopment() {
      return process.env.NODE_ENV == "development";
    },
  },
};
</script>
