<template>
  <v-menu :offset-y="position == 'bottom'" :offset-x="position == 'right'">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        v-bind="attrs"
        v-on="on"
        :class="highlight ? 'v-btn--active' : ''"
        text
      >
        {{ navText.toUpperCase() }}
      </v-btn>
    </template>

    <v-list dark dense>
      <v-list-item v-for="(item, i) in items" :key="i" :to="item.path">
        <v-list-item-title>
          {{ item.name.toUpperCase() }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["items", "navText", "position"],

  computed: {
    ...mapState(["dataLoaded"]),

    highlight() {
      if (!this.dataLoaded || this.$route.matched.length == 0) return false;

      return (
        this.items.filter(({ path }) => {
          return path == this.$route.fullPath;
        }).length > 0
      );
    },
  },
};
</script>
