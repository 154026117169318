module.exports = {
  state: () => ({
    awards: [],
    biblio: [],
    biblioPubs: [],
    bio: [],
    coverList: [],
    events: [],
    freebies: [],
    honors: [],
    news: [],
    lessonsBlessings: [],
    lifePoems: [],
    navDropdowns: [],
    navigation: [],
    publications: [],
    reviewsQuotes: [],
    seeHearRead: [],
    social: []
  })
};
