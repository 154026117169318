const pageConfig = require("@/store/actions/assets/page-config");
const config = pageConfig();

const Home = () => import("@/views/Home");

const ReviewsQuotes = () => import("@/views/ReviewsQuotes");
const Biblio = () => import("@/views/Biblio");
const Biography = () => import("@/views/Biography");
const Contact = () => import("@/views/Contact");
const Events = () => import("@/views/Events");
const LessonsBlessings = () => import("@/views/LessonsBlessings");
const OnlineResources = () => import("@/views/OnlineResources");
const Freebies = () => import("@/views/Freebies");
const LatestNews = () => import("@/views/LatestNews");
const SeeHearRead = () => import("@/views/SeeHearRead");
const Publications = () => import("@/views/Publications");
const Awards = () => import("@/views/Awards");
const Honors = () => import("@/views/Honors");

module.exports = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/reviews-and-quotes",
    name: "Reviews & Quotes",
    component: ReviewsQuotes,
    meta: { config: config.ReviewsQuotes }
  },
  {
    path: "/bibliography",
    name: "Bibliography",
    component: Biblio,
    alias: "/biblio",
    meta: { config: config.biblio }
  },
  {
    path: "/biography",
    name: "Biography",
    component: Biography,
    alias: "/bio",
    meta: { config: config.bio }
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: { config: config.contact }
  },
  {
    path: "/events",
    name: "Events",
    component: Events,
    meta: { config: config.events }
  },
  {
    path: "/lessons-blessings",
    name: "Lessons & Blessings",
    component: LessonsBlessings,
    alias: "/lessons-and-blessings",
    meta: { config: config.lessonsBlessings }
  },
  {
    path: "/online-resources",
    name: "Online Resources",
    component: OnlineResources,
    meta: { config: config.onlineResources }
  },
  {
    path: "/freebies",
    name: "Freebies",
    component: Freebies,
    meta: { config: config.freebies }
  },
  {
    path: "/latest-news",
    name: "Latest News",
    component: LatestNews,
    meta: { config: config.latestNews }
  },
  {
    path: "/see-hear-read",
    name: "See / Hear / Read",
    component: SeeHearRead,
    meta: { config: config.seeHearRead }
  },
  {
    path: "/publications",
    name: "Publications",
    component: Publications,
    meta: { config: config.publications }
  },
  {
    path: "/awards",
    name: "Awards",
    component: Awards,
    meta: { config: config.awards }
  },
  {
    path: "/honors",
    name: "Honors",
    component: Honors,
    meta: { config: config.honors }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login")
  }
];
