const wretch = require("wretch").default;

module.exports = ({ state }, { table, _id }) => {
  state, wretch;
  wretch(`/api/admin/delete-rec`)
    .formData({
      token: state.adminToken,
      table,
      _id
    })
    .post()
    .res()
    .then(() => {
      state.admin[table] = state.admin[table].filter(rec => rec._id != _id);
    });
};
