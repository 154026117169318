const wretch = require("wretch").default;

module.exports = ({ state }, { email, password }) => {
  state, email, password, wretch;

  return wretch("/api/admin/login")
    .formData({ email, password })
    .post()
    .json()
    .then(({ status, message, token }) => {
      if (status == "ok") {
        sessionStorage.setItem("admin-token", token);
        state.adminToken = token;
      }
      return { status, message, token };
    });
};
