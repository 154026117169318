module.exports = (state) => {
  if (!state.biblio) return [];

  return Object.keys(state.biblio).map((key) => {
    return state.biblio[key].entries.map((entry) => {
      entry.reprints = entry.reprints.map(
        (reprint) => `${reprint.publication} (${reprint.date})`
      );

      return entry;
    });
  });
};
