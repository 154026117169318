const marked = require("marked");
const renderer = new marked.Renderer();

renderer.link = function(href, title, text) {
  return (
    '<a target="_blank" href="' +
    href +
    '" title="' +
    title +
    '">' +
    text +
    "</a>"
  );
};

module.exports = () => {
  return {
    navigation: {
      winVar: "navigation",
      stateVar: "navigation",
    },
    lifePoem: {
      apiPath: "life-poem",
      stateVar: "poem",
    },
    social: {
      winVar: "social",
      stateVar: "social",
      callback: (data) => {
        return data.sort((a, b) => a.position - b.position);
      },
    },
    bookList: {
      winVar: "bookList",
      stateVar: "coverList",
    },
    latestNews: {
      winVar: "latestNews",
      stateVar: "latestNews",
    },
    reviewsQuotes: {
      winVar: "reviewsQuotes",
      stateVar: "reviewsQuotes",
    },
    biblio: {
      winVar: "biblio",
      stateVar: "biblio",
    },
    events: {
      winVar: "events",
      stateVar: "events",
      callback: (data) => {
        return data
          .sort(
            (a, b) =>
              new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
          )
          .map((e) => {
            e.details = marked(e.details, { renderer: renderer });
            return e;
          });
      },
    },
    bio: {
      winVar: "bio",
      stateVar: "bio",
    },
    lessonsBlessings: {
      winVar: "lessonsBlessings",
      stateVar: "lessonsBlessings",
      callback: (data) => {
        return {
          header: marked(data.header, { renderer: renderer }),
          poem: marked(data.poem, { renderer: renderer }),
        };
      },
    },
    freebies: {
      apiPath: "freebies",
      stateVar: "freebies",
      callback: ({ title, note, sub, story, next }) => {
        return {
          title: marked(title, { renderer: renderer }),
          note: note != "" ? marked(note, { renderer: renderer }) : "",
          sub: sub != "" ? marked(sub, { renderer: renderer }) : "",
          story: marked(story, { renderer: renderer }),
          next,
        };
      },
    },
    onlineResources: {
      winVar: "onlineResources",
      stateVar: "onlineResources",
      callback: (data) => {
        return data.sort((a, b) => (a.header < b.header ? -1 : 1));
      },
    },
    seeHearRead: {
      winVar: "seeHearRead",
      stateVar: "seeHearRead",
    },
    publications: {
      winVar: "publications",
      stateVar: "publications",
    },
    awards: {
      winVar: "awards",
      stateVar: "awards",
    },
    honors: {
      winVar: "honors",
      stateVar: "honors",
    },
  };
};
