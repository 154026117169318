import Vue from "vue";
import VueRouter from "vue-router";

import wretch from "wretch";
import uniqid from "uniqid";

Vue.use(VueRouter);

const routes = [
  ...require("./public-routes"),
  ...require("./admin-routes"),
  {
    path: "*",
    name: "404",
    component: () => import("@/views/PageNotFound")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(rec => rec.meta.requireAuth)) {
    const token = sessionStorage.getItem("admin-token");
    const sigID = uniqid();
    wretch(`/api/admin/verify-token/${token}/${sigID}`)
      .get()
      .json()
      .then(({ status, signature }) => {
        if (status == "ok" && signature == sigID) {
          next();
        } else {
          next("/login");
        }
      });
  } else {
    next();
  }
});

export default router;
