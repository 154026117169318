import Vue from "vue";
import Vuex from "vuex";

import wretch from "wretch";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    dataLoaded: false,
    navigation: [],
    lifePoem: "",
    latestNews: [],
    reviewsQuotes: [],
    biblio: null,
    bio: null,
    social: [],
    events: [],
    lessonsBlessings: { header: "", poem: "" },
    onlineResources: [],
    freebie: {},
    coverList: [],
    seeHearRead: [],
    publications: {},
    awards: [],
    honors: [],

    sendingContact: false,

    adminToken: sessionStorage.getItem("admin-token"),

    adminData: {}
  },
  getters: {
    navItems: require("./getters/nav-items"),
    biblioEntries: require("./getters/biblio-entries"),
    coverList: require("./getters/cover-list")
  },
  mutations: {
    setNav(state, data) {
      state.navigation = data;
    }
  },
  actions: {
    // getPageData: require("./actions/get-page-data"),
    loadSiteData: require("./actions/load-site-data"),
    sendContact: require("./actions/send-contact"),
    login: require("./actions/login"),
    getAdminData: require("./actions/get-admin-data"),
    adminDeleteItem: require("./actions/admin-delete-item"),
    adminUpsertItem: require("./actions/admin-upsert-item"),
    trackingInfo() {
      const id = window.location.search.substring(1);
      wretch(`/api/traffic-log/${id}`).get();
    }
  },
  modules: {
    admin: require("./admin-state")
  }
});
