<template>
  <div>
    <v-row>
      <v-card-text
        :class="$vuetify.breakpoint.mdAndUp ? 'display-1' : 'text-h5'"
        class="font-weight-bold"
      >
        Linda D. Addison - Writer/Poet
      </v-card-text>
    </v-row>
    <v-row>
      <v-col></v-col>
      <v-col cols="12" sm="6">
        <v-card flat color="rgb(255,255,255,0.7)">
          <v-card-title>Today's Life Poem</v-card-title>
          <v-card-text v-html="poem"></v-card-text>
        </v-card>
      </v-col>
      <v-col></v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  computed: {
    poem() {
      return this.$store.state.lifePoem;
    },
  },
};
</script>
