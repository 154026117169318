module.exports = [
  {
    path: "/admin",
    name: "Admin",
    component: () => import("@/views/Admin"),
    meta: { requireAuth: true },
    children: [
      {
        path: "awards",
        name: "Awards Admin",
        component: () => import("@/views/admin/AdminTable")
      },
      {
        path: "biblio",
        name: "Biblio Admin",
        component: () => import("@/views/admin/AdminTable")
      },
      {
        path: "bio",
        name: "Bio Admin",
        component: () => import("@/views/admin/AdminTable")
      },
      {
        path: "cover-list",
        name: "Cover List Admin",
        component: () => import("@/views/admin/AdminTable")
      },
      {
        path: "events",
        name: "Events Admin",
        component: () => import("@/views/admin/AdminTable")
      },
      {
        path: "freebies",
        name: "Freebies Admin",
        component: () => import("@/views/admin/AdminTable")
      },
      {
        path: "honors",
        name: "Honors Admin",
        component: () => import("@/views/admin/AdminTable")
      },
      {
        path: "latest-news",
        name: "News Admin",
        component: () => import("@/views/admin/AdminTable")
      },
      {
        path: "lessons-blessings",
        name: "Lessons & Blessings Admin",
        component: () => import("@/views/admin/AdminTable")
      },
      {
        path: "life-poems",
        name: "Life Poems Admin",
        component: () => import("@/views/admin/AdminTable")
      },
      {
        path: "navigation",
        name: "Navigation Admin",
        component: () => import("@/views/admin/AdminTable")
      },
      {
        path: "publications",
        name: "Publications Admin",
        component: () => import("@/views/admin/AdminTable")
      },
      {
        path: "reviews-quotes",
        name: "Reviews & Quotes Admin",
        component: () => import("@/views/admin/AdminTable")
      },
      {
        path: "see-hear-read",
        name: "See / Hear / Read Admin",
        component: () => import("@/views/admin/AdminTable")
      },
      {
        path: "social",
        name: "Social Media Icons Admin",
        component: () => import("@/views/admin/AdminTable")
      }
    ]
  }
];
