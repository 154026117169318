const wretch = require("wretch").default;
const { decompress } = require("../util/gz");

module.exports = ({ state }) => {
  state;
  wretch(`/api/admin/get-data/${state.adminToken}`)
    .get()
    .json()
    .then(({ status, message, data }) => {
      if (status != "ok") {
        console.error(message);
        return false;
      } else {
        return JSON.parse(decompress(data));
      }
    })
    .then(data => {
      Object.keys(data).forEach(key => {
        state.admin[key] = data[key];
      });
    });
};
