<template>
  <v-row>
    <template v-for="item in navItems">
      <v-btn
        text
        :key="item.id"
        :to="item.path"
        v-if="item.type != 'spacer' && !item.children"
        >{{ item.name }}</v-btn
      >
      <v-spacer v-if="item.type == 'spacer'" :key="item.id"></v-spacer>
      <nav-dropdown
        v-if="item.children"
        :key="item.id"
        :items="item.children"
        :nav-text="item.name"
        position="bottom"
      ></nav-dropdown>
    </template>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import NavDropdown from "./NavDropdown.vue";

export default {
  components: { NavDropdown },
  computed: {
    ...mapGetters(["navItems"]),
  },
};
</script>
