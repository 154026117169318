const wretch = require("wretch").default;

module.exports = async ({ state }, contactData) => {
  state.sendingContact = true;

  const response = await wretch("/api/send-contact")
    .body(JSON.stringify(contactData))
    .post()
    .json();

  state.sendingContact = false;

  return response;
};
